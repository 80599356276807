import React from 'react';

import { Page } from '@tager/web-components';
import { isServer } from '@tager/web-core';

import { useTypedSelector } from '@/store/store';
import { CustomApp_PageContext } from '@/typings/hocs';
import Layout from '@/components/Layout';
import Home from '@/modules/Home';
import {
  getIndexPageSeoParamsThunk,
  selectIndexPageSeoParams,
  getHomePostListThunk,
} from '@/store/reducers/pages/home';
import { getSharedThunkList } from '@/utils/thunks';

function HomePage() {
  const seoParams = useTypedSelector((state) =>
    selectIndexPageSeoParams(state)
  );

  if (!seoParams) return null;

  return (
    <Page
      title={seoParams.title}
      description={seoParams.description}
      openGraphImage={seoParams.openGraphImage}
    >
      <Layout>
        <Home />
      </Layout>
    </Page>
  );
}

HomePage.getInitialProps = async (context: CustomApp_PageContext) => {
  const { store, lang } = context;

  const requestsPromise = Promise.all([
    ...getSharedThunkList(store.dispatch, lang),

    store.dispatch(getIndexPageSeoParamsThunk(lang)),
    store.dispatch(getHomePostListThunk(lang)),
  ]);

  if (isServer()) {
    await requestsPromise;
  }

  return { pageType: 'PRODUCT' };
};

export default HomePage;
