import React, { useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { useTranslation } from '@components/I18nProvider';

import { convertThumbnailToPictureImage } from '@tager/web-modules';

import { scroller } from '@tager/web-core';
import { PostShort } from '@/typings/model';
import Picture from '@/components/Picture';
import PostMeta from '@/components/PostMeta/PostMeta';
import { getPostDate, getPostUrl } from '@/utils/posts';
import { media } from '@/utils/mixin';
import Link from '@/components/Link';
import Bookmark from '@/components/Bookmark';

type Props = {
  post: PostShort;
};

function PostCard({ post }: Props) {
  const { t } = useTranslation();

  return (
    <Card>
      {post.coverImage ? (
        <Left onClick={() => scroller.unlock()}>
          <Link to={getPostUrl(post)}>
            <ImageContainer>
              <Picture
                tabletSmall={convertThumbnailToPictureImage(
                  post.coverImage.preview
                )}
                mobileSmall={convertThumbnailToPictureImage(
                  post.coverImage.previewMobile
                )}
              />
            </ImageContainer>
          </Link>
        </Left>
      ) : null}
      <Right>
        <Content>
          <Link as={Title} to={getPostUrl(post)}>
            {post.title}
          </Link>
          <Excerpt dangerouslySetInnerHTML={{ __html: post.excerpt ?? '' }} />
        </Content>
        <Footer>
          <Bookmark post={post} />
          <PostMeta
            date={getPostDate(post.date, t)}
            category={
              post.categories && post.categories.length
                ? post.categories[0].name
                : null
            }
          />
        </Footer>
      </Right>
    </Card>
  );
}

const Card = styled.div`
  display: flex;
  margin-bottom: 50px;

  ${media.mobile(css`
    display: block;
  `)}
`;

const Right = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1px;
  overflow: hidden;
  ${media.mobile(css`
    margin-top: 9px;
  `)}
`;

const Content = styled.div``;

const Footer = styled.div`
  margin-top: auto;
  display: flex;
`;

const Title = styled.a`
  font-size: 36px;
  font-weight: 700;
  font-style: normal;
  line-height: 42px;
  display: block;
  margin-bottom: 10px;
  transition: none;

  &:hover {
    color: #ed4040;
  }
  ${media.tabletLargeOnly(css`
    font-size: 30px;
    line-height: 34px;
  `)}
  ${media.tabletSmallOnly(css`
    font-size: 24px;
    line-height: 28px;
  `)}

  ${media.mobile(css`
    font-size: 20px;
    line-height: 23px;
  `)}
`;

const Excerpt = styled.p`
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 15px;
  ${media.mobile(css`
    font-size: 15px;
    line-height: 22px;
  `)}
`;

const Left = styled.div`
  flex-basis: 750px;
  min-width: 750px;
  margin-top: 5px;
  height: 344px;
  overflow: hidden;
  position: relative;

  ${media.tabletLargeOnly(css`
    flex-basis: 640px;
    min-width: 640px;
  `)}

  ${media.tabletSmall(css`
    height: auto;
  `)}
  
  ${media.tabletSmallOnly(css`
    min-width: 300px;
    flex-basis: 300px;
  `)}
  
   ${media.mobileLargeOnly(css`
    height: 210px;
  `)}   
   
   ${media.mobile(css`
    flex-basis: 750px;
    min-width: 0;
    margin: 0 -15px 10px;
  `)}   
   &:hover + ${Right} ${Title} {
    color: #ed4040;
  }
`;

const ImageContainer = styled.div`
  width: 700px;
  height: 346px;
  background-color: #eee;
  img {
    width: 700px;
  }

  ${media.tabletLargeOnly(css`
    width: 600px;
    height: 297px;
    img {
      width: 600px;
    }
  `)}

  ${media.tabletSmall(css`
    img {
      position: static;
      transform: none;
      top: 0;
    }
  `)}
  
  ${media.tabletSmallOnly(css`
    width: 280px;
    height: 138px;
    img {
      width: 280px;
    }
  `)}

  ${media.mobile(css`
    width: 450px;
    height: 211px;
    margin: auto;
    img {
      max-width: 450px;
      width: 100%;
      margin: 0 auto;
      height: auto;
    }
  `)}
  
   ${media.mobileMedium(css`
    width: auto;
    min-height: 158px;
    height: auto;
  `)}
`;
export default PostCard;
