import { StoreDispatch } from '@/store/store';
import { getMenuItemListThunk } from '@/store/reducers/tager/menus';
import { LangType } from '@/typings/common';
import { withLang } from '@/utils/common';
import { getBookmarksThunk } from '@/store/reducers/pages/bookmarks';

export function getSharedThunkList(dispatch: StoreDispatch, lang: LangType) {
  return [
    dispatch(getMenuItemListThunk(withLang('header_menu', lang))),
    dispatch(getMenuItemListThunk(withLang('dropdown_menu', lang))),
    dispatch(getMenuItemListThunk(withLang('footer_menu', lang))),
    dispatch(getBookmarksThunk()),
  ] as const;
}
