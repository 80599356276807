import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { useTranslation } from '@components/I18nProvider';

import { FETCH_STATUSES } from '@tager/web-core';

import { useTypedSelector } from '@/store/store';
import {
  getNextHomePostListThunk,
  selectPostListResource,
} from '@/store/reducers/pages/home';
import PostList from '@/components/PostList';
import PostPlaceholderList from '@/components/PostPlaceholderList';

function Home() {
  const postListResource = useTypedSelector((state) =>
    selectPostListResource(state)
  );
  const { language } = useTranslation();
  const postListRef = useRef<HTMLUListElement>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!postListRef.current || !postListResource.hasMore) return;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const isLastRow =
            entry.target.id === `post-row-${postListResource.data.length}`;
          const isPostListLoading = postListResource.status === 'LOADING_MORE';

          if (isLastRow && entry.isIntersecting && !isPostListLoading) {
            dispatch(getNextHomePostListThunk(language));
          }
        });
      },
      {
        threshold: 0.2,
      }
    );

    Array.from(postListRef.current.children).forEach((postRowElement) => {
      observer.observe(postRowElement);
    });

    return () => observer.disconnect();
  }, [
    dispatch,
    postListResource.data.length,
    postListResource.hasMore,
    postListResource.status,
  ]);

  return (
    <Container>
      {postListResource.status === FETCH_STATUSES.LOADING ? (
        <PostPlaceholderList />
      ) : null}

      <PostList postList={postListResource.data} ref={postListRef} />
      {postListResource.status === 'LOADING_MORE' ? (
        <PostPlaceholderList />
      ) : null}
    </Container>
  );
}

const Container = styled.div``;

export default Home;
