import React from 'react';
import styled from 'styled-components';

import { generateNumberArray } from '@tager/web-core';

import PostPlaceholder from './components/PostPlaceholder';

function PostPlaceholderList() {
  return (
    <List>
      {generateNumberArray(10).map((num) => (
        <PostPlaceholder key={num} />
      ))}
    </List>
  );
}

const List = styled.ul``;

export default PostPlaceholderList;
