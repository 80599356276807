import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { customPlaceholder } from '@/components/CustomPlaceholder/CustomPlceholder';

function PostPlaceholder() {
  return (
    <Container>
      <Left>
        <Image />
      </Left>
      <Right>
        <Content>
          <Title />
          <Excerpt />
        </Content>
        <Footer />
      </Right>
    </Container>
  );
}

const Container = styled.li`
  display: flex;
  margin-bottom: 75px;
  ${media.mobile(css`
    display: block;
  `)}
`;
const Right = styled.div`
  display: flex;
  flex: 1px;
  flex-direction: column;
  overflow: hidden;
  ${media.mobile(css`
    margin-top: 9px;
  `)}
`;

const Content = styled.div``;

const Title = styled.div`
  height: 17px;
  margin-bottom: 10px;
  transition: none;
  ${customPlaceholder}
`;

const Excerpt = styled.div`
  height: 17px;
  margin-bottom: 15px;
  ${customPlaceholder}
`;

const Footer = styled.div`
  margin-top: auto;
  display: flex;
  height: 17px;
  ${customPlaceholder}
`;
const Left = styled.div`
  flex-basis: 700px;
  margin-right: 50px;
  ${media.tabletLargeOnly(css`
    flex-basis: 600px;
    margin-right: 35px;
  `)}
  ${media.tabletSmallOnly(css`
    flex-basis: 300px;
    margin-right: 25px;
  `)}
   ${media.mobile(css`
     margin: 0 -15px 10px;
   `)}      

`;

const Image = styled.div`
   min-width: 700px;
   height: 345px;
   ${customPlaceholder}
   ${media.tabletLargeOnly(css`
     min-width: 600px;
     height: 296px;
   `)}
   ${media.tabletSmallOnly(css`
     height: 138px;
   `)}
   ${media.mobile(css`
     margin: 0 auto;
     min-width: 414px;
     height: 222px;
   `)}
    ${media.mobileMedium(css`
      min-width: auto;
      margin: 0 auto;
      width: 100%;
      height: 222px;
    `)}
`;
export default PostPlaceholder;
