import React from 'react';
import styled, { css } from 'styled-components';

import { media } from '@/utils/mixin';
import { PostShort } from '@/typings/model';
import PostCard from '@/components/PostCard';
import { useTranslation } from '@/components/I18nProvider';

type Props = {
  postList: Array<PostShort>;
};

const PostList = React.forwardRef<HTMLUListElement, Props>(
  function PostListWithRef(props, ref) {
    const { t } = useTranslation();
    return props.postList.length !== 0 ? (
      <List ref={ref}>
        {props.postList.map((post, index) => (
          <Row
            key={post.id}
            preview={!!post.coverImage}
            id={`post-row-${index + 1}`}
          >
            <PostCard post={post} />
          </Row>
        ))}
      </List>
    ) : (
      <NoRecords> {t('noArticles')}</NoRecords>
    );
  }
);

const List = styled.ul`
  &:not(:first-child) {
    //padding-top: 75px;
  }
`;

const Row = styled.li<{ preview: boolean }>`
  margin-top: 75px;
  &:first-child {
    margin-top: 0;
  }
  ${(props) =>
    props.preview
      ? css`
           min-height: 349px;
          ${media.tabletLarge(css`
            min-height: 300px;
          `)}
          ${media.tabletSmallOnly(css`
            min-height: 143px;
            margin-top: 50px;
          `)}

          ${media.mobile(css`
            min-height: auto;
            margin-top: 35px;
          `)}
        `
      : null}

  ${media.tabletSmallOnly(css`
    margin-top: 50px;
  `)}

  ${media.mobile(css`
    margin-top: 35px;
  `)}
`;

const NoRecords = styled.span`
  display: block;
  text-align: center;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
`;

export default PostList;
